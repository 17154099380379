import axios from "axios";
import * as ConfrimNewUserActions from "../actions/confirmNewUser";
import { Api } from "../enums/api";
import {
  envLinkSetter,
  errorFromResponse,
  errorResponseNotification,
} from "../utils";
import * as AuthCreators from "creators/auth";
import { Action } from "redux";
import { sid$, status_session$ } from "selectors/auth";
import State from "interfaces/state";
import * as AuthActions from "actions/auth";
import i18n from "i18n";
import { useLocation, useNavigate } from "react-router-dom";
import { privateRoute } from "routes";

let tok = "";
export const getSessionStatusInit = (
  thenFunc: "email" | "link",
  location: ReturnType<typeof useLocation>,
  navigate: ReturnType<typeof useNavigate>,
  searchParams: URLSearchParams
) => {
  return (dispatch: (action: Action) => void, _getState: () => State) => {
    // eslint-disable-next-line no-lone-blocks
    dispatch(AuthActions.setAuthLoading());

    axios
      .post(Api.GetSessionStatus)
      .then((res) => {
        if (res?.data === null) {
          dispatch(AuthActions.setAuthFail());
          errorResponseNotification(res?.status as 400, 5000, res?.data?.error);

          dispatch(
            AuthCreators.statusControl(
              res.status,
              navigate,
              location,
              searchParams
            ) as any
          );
          dispatch(AuthActions.setSessionStatus("unknown"));
        } else {
          const newResponse = { ...res.data.response };
          newResponse.session_status = "init";
          dispatch(AuthActions.setAuthParams(newResponse));
        }
      })
      .then(() => {
        if (thenFunc === "email") {
          dispatch(
            confirmJoiningFromEmail(
              tok,
              location,
              navigate,
              searchParams
            ) as any
          );
        }
      })
      .catch(async (error) => {
        errorResponseNotification(
          error.response.status as 400,
          5000,
          error?.response?.data?.error
        );

        dispatch(
          AuthCreators.statusControl(
            error.response.status,
            navigate,
            location,
            searchParams
          ) as any
        );
        dispatch(AuthActions.setSessionStatus("unknown"));
        dispatch(AuthActions.setAuthFail());
        console.error(error);
      });
  };
};

export const confirmJoiningFromEmail = (
  token: string,
  location: ReturnType<typeof useLocation>,
  navigate: ReturnType<typeof useNavigate>,
  searchParams: URLSearchParams
) => {
  return (dispatch: (action: Action) => void, getState: () => State) => {
    if (status_session$(getState()) === "open") {
      const withoutSessionStatus = true;

      dispatch(
        AuthCreators.logOut(
          location,
          navigate,
          searchParams,
          token,
          withoutSessionStatus,
          AuthCreators.LogOutDispatch.CONFIRM_BY_EMAIL
        ) as any
      );
    } else {
      if (!sid$(getState())) {
        dispatch(
          getSessionStatusInit("email", location, navigate, searchParams) as any
        );
      } else {
        dispatch(ConfrimNewUserActions.setConfirmNewUserLoading());
        axios({
          url: Api.ConfirmJoining,
          method: "POST",
          data: {
            token,
            link: `${envLinkSetter()}/app/confirm_joining?token={token}`,
          },
          responseType: "json",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((res) => {
            dispatch(ConfrimNewUserActions.setConfirmNewUserSuccess());
            if (res.data) {
              dispatch(
                ConfrimNewUserActions.setNewUserEmail(res.data.response.email)
              );
              if (res?.data?.response?.user_name) {
                dispatch(
                  ConfrimNewUserActions.setUserName(
                    res!.data!.response!.user_name
                  )
                );
              }
              if (res?.data?.response?.company_name) {
                dispatch(
                  ConfrimNewUserActions.setCompanyName(
                    res!.data!.response!.company_name
                  )
                );
              }
            }
            if (res.status) {
              dispatch(
                AuthCreators.statusControl(
                  res.status,
                  navigate,
                  location,
                  searchParams
                ) as any
              );
            }
          })
          .catch((err) => {
            console.log(err.response);
            dispatch(ConfrimNewUserActions.setConfirmNewUserError());
            if (err?.response) {
              errorResponseNotification(
                err?.response.status as 400,
                5000,
                err?.response?.data?.error
              );
            }
            console.error(err.response);
          });
      }
    }
  };
};

export const sendUserData = (
  first_name: string,
  password: string,
  navigate: ReturnType<typeof useNavigate>,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams
) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(ConfrimNewUserActions.setConfirmNewUserLoading());

    axios({
      url: Api.SetNameAndPass,
      method: "POST",
      data: {
        first_name,
        password,
      },
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        dispatch(ConfrimNewUserActions.setConfirmNewUserSuccess());
        dispatch(ConfrimNewUserActions.setIsconfirmedNewUser());
        if (res?.data?.response?.user_name) {
          dispatch(
            ConfrimNewUserActions.setUserName(res!.data!.response!.user_name)
          );
        }
        if (res?.data?.response?.company_name) {
          dispatch(
            ConfrimNewUserActions.setCompanyName(
              res!.data!.response!.company_name
            )
          );
        }

        navigate(privateRoute.dashboard);

        dispatch(
          AuthCreators.getSessionStatus(navigate, location, searchParams) as any
        );
      })
      .catch((err) => {
        dispatch(ConfrimNewUserActions.setConfirmNewUserError());
        dispatch(
          ConfrimNewUserActions.setNewUserConfirmErrorText(
            i18n.t(err.response.error)
          )
        );
        dispatch(
          AuthCreators.statusControl(
            err.status,
            navigate,
            location,
            searchParams
          ) as any
        );
        const error = err?.response?.data?.error;

        errorResponseNotification(
          err.response.status as 400,
          5000,
          i18n.t(error)
        );

        console.error(err.response);
      });
  };
};

export const confirmTokenByLinkFromEmail = (
  token: string,
  navigate: ReturnType<typeof useNavigate>,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams
) => {
  tok = token;
  return (dispatch: (action: Action) => void, getState: () => State) => {
    dispatch(ConfrimNewUserActions.setConfirmNewUserLoading());

    const confirm = () =>
      axios({
        url: Api.ConfirmByLink,
        method: "POST",
        data: {
          token,
          link: `${envLinkSetter()}/app/confirm_joining?token={token}`,
        },
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => {
          console.log(res);
          if (res?.status === 204) {
            dispatch(ConfrimNewUserActions.setIsconfirmedNewUser());
            dispatch(ConfrimNewUserActions.setConfirmNewUserSuccess());
            dispatch(
              AuthCreators.getSessionStatus(
                navigate,
                location,
                searchParams,
                undefined,
                undefined,
                true
              ) as any
            );
          } else if (
            res?.status === 200 &&
            res?.data?.response?.status === "member_was_added"
          ) {
            dispatch(ConfrimNewUserActions.setConfirmNewUserSuccess());
            dispatch(ConfrimNewUserActions.setRegisteredUser());
            dispatch(
              AuthCreators.getSessionStatus(
                navigate,
                location,
                searchParams,
                undefined,
                undefined,
                true
              ) as any
            );
          }

          if (res?.data?.response?.user_name) {
            dispatch(
              ConfrimNewUserActions.setUserName(res!.data!.response!.user_name)
            );
          }
          if (res?.data?.response?.company_name) {
            dispatch(
              ConfrimNewUserActions.setCompanyName(
                res!.data!.response!.company_name
              )
            );
          }
        })
        .catch((err) => {
          dispatch(ConfrimNewUserActions.setConfirmNewUserError());
          if (!err || !err?.response) {
            return;
          } else {
            dispatch(
              AuthCreators.statusControl(
                err?.status,
                navigate,
                location,
                searchParams
              ) as any
            );
            dispatch(
              ConfrimNewUserActions.setNewUserConfirmErrorText(
                errorFromResponse(err?.response?.data?.error) as string
              )
            );
            errorResponseNotification(
              err.response.status as 400,
              5000,
              err?.response?.data?.error
            );
            console.error(err?.response);
          }
        });

    confirm();
  };
};

export const createAccountFromLink = (
  email: string,
  token: string,
  navigate: ReturnType<typeof useNavigate>,
  location: ReturnType<typeof useLocation>,
  searchParams: URLSearchParams
) => {
  return (dispatch: (action: Action) => void) => {
    dispatch(ConfrimNewUserActions.setConfirmNewUserLoading());

    axios({
      url: Api.CreateAccByLink,
      method: "POST",
      data: {
        email,
        link: `${envLinkSetter()}/app/confirm_joining?token={token}`,
        token,
      },
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 204) {
          dispatch(ConfrimNewUserActions.setConfirmNewUserSuccess());
        }
      })
      .catch((err) => {
        dispatch(ConfrimNewUserActions.setConfirmNewUserError());
        dispatch(
          AuthCreators.statusControl(
            err.status,
            navigate,
            location,
            searchParams
          ) as any
        );
        dispatch(
          ConfrimNewUserActions.setNewUserConfirmErrorText(
            err.response.data.error
          )
        );

        console.error(err.response);
      });
  };
};
